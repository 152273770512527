.animations {
  .like {
    position: absolute;
    pointer-events: none;
    width: 32px;
    height: 32px;
    background: url("../../../assets/kollegi/like.svg") no-repeat center center;
    background-size: 32px;
    @for $i from 1 to 30 {
      &.t-#{$i} {
        animation: like-#{$i} 2s ease 1 both;
      }
      @keyframes like-#{$i} {
        0% {
          transform: translate(0, 0) scale(0) rotate(#{random(40)-20}deg);
          opacity: 0;
        }
        80% {
          opacity: 0.5;
        }
        100% {
          opacity: 0;
          transform: translate(#{random(20)-10}px, -#{random(70) + 70}px)
            scale(1) rotate(#{random(40)-20}deg);
        }
      }
    }
  }
}

.like-press-btn {
  transition: 0.2s ease;
  box-shadow: 0 3px 16px rgba(239, 68, 68, 0.75);
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.95);
  }
}

.questions1 {
  .question {
    font-size: 13px;
    max-width: calc(100% - 100px);
    border-radius: 12px 12px 12px 0;
    background: #e8e9ea; //#FFB000;
    margin-right: auto;
    margin-left: 40px;
    position: relative;
    &.hidden {
      display: none;
    }
    &.admin {
      display: block;
      &.hidden {
        background: #f9f9f9;
        color: #d0d0d0;
        .text {
          max-height: 20px;
          overflow: hidden;
        }
        .author .text-primary {
          color: #d0d0d0 !important;
        }
      }
    }
    .author {
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    .btn-hide {
      width: 30px;
      height: 30px;
      position: absolute;
      right: -60px;
      color: #cacaca;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .btn-like {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      position: absolute;
      right: -20px;
      top: 5px;
      color: #4caf50;
      border: 1px solid;
      background: white;
      padding: 4px;
      &.liked {
        background: #4caf50;
        color: white;
      }
      .icon {
        min-width: 18px;
        min-height: 18px;
      }
      small {
        font-size: 10px;
        font-weight: bold;
      }
    }
    .photo {
      width: 30px;
      height: 30px;
      position: absolute;
      background: center center no-repeat #e5e5e5;
      background-size: cover;
      border-radius: 15px;
      bottom: 5px;
      left: -40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
    }
  }
  .question-form {
    .photo {
      width: 30px;
      height: 30px;
      background: center center no-repeat #e5e5e5;
      background-size: cover;
      border-radius: 15px;
      color: black;
    }
  }
}
.questions::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
.questions::-webkit-scrollbar-track {
  background-color: rgba(79, 70, 229, 0.1);
  cursor: pointer;
}
.questions::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: rgb(0, 3, 10);
}

.last-question {
  &:before {
    content: "";
    background-image: url("../../../assets/new.svg");
    background-size: 80px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: newMovement 2s linear infinite;
  }
  @keyframes newMovement {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -80px 0;
    }
  }
}

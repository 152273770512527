.song {
  animation: moveText 170s linear 1 both;

  @keyframes moveText {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -100%);
    }
  }
}

.tree {
  .speed-1 {
    opacity: 0;
  }
  .speed-2 {
    opacity: 0;
  }
  .speed-3 {
    opacity: 0;
  }
  .speed-4 {
    opacity: 0;
  }
  &.start {
  }
  &.speed-1 {
    .speed-1 {
      animation: treePulse 4s ease infinite both;
    }
  }
  &.speed-2 {
    .speed-1 {
      animation: treePulse 4s ease infinite both;
    }
    .speed-2 {
      animation: treePulse 2s 1s ease infinite both;
    }
  }
  &.speed-3 {
    .speed-1 {
      animation: treePulse 4s ease infinite both;
    }
    .speed-2 {
      animation: treePulse 2s 1s ease infinite both;
    }
    .speed-3 {
      animation: treePulse 1.7s 1.7s ease infinite both;
    }
  }
  &.speed-4 {
    .speed-1 {
      animation: treePulse 4s ease infinite both;
    }
    .speed-2 {
      animation: treePulse 2s 1s ease infinite both;
    }
    .speed-3 {
      animation: treePulse 1.7s 1.7s ease infinite both;
    }
    .speed-4 {
      animation: treePulse 4.7s 2s ease infinite both;
    }
  }
  &.speed-5 {
    .speed-1 {
      animation: treePulseFinal 2.4s ease infinite both;
    }
    .speed-2 {
      animation: treePulseFinal 2s 0.5s ease infinite both;
    }
    .speed-3 {
      animation: treePulseFinal 1.7s 0.7s ease infinite both;
    }
    .speed-4 {
      animation: treePulseFinal 1.7s 0.3s ease infinite both;
    }
  }
}
@keyframes treePulseFinal {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes treePulse {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import "fonts/stylesheet.css";

body {
  color: #1d2b48;
  font-family: "Gotham", sans-serif;
  background: url("./assets/pitcha-demo/back.jpg") no-repeat center center
    #1e252b;
  background-size: cover;
  background-attachment: fixed;
}

.text-alternate {
  font-family: "Montserrat", sans-serif;
}

.bg-black {
  background-color: rgb(0, 3, 10);
}

$primary: #fdc200;
$secondary: #c8beaf;
.text-primary {
  color: $primary;
}
.text-secondary {
  color: $secondary;
}
.bg-secondary {
  background-color: $secondary;
}

.bg-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.animate-grow {
  animation: grow 3.3s ease 1 both;
  @keyframes grow {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.7);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
.animate-grow-up-down {
  animation: growUpDown 2s ease infinite both;
  @keyframes growUpDown {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.7);
    }
  }
}
.show-from-top {
  animation: showFromTop 0.7s ease 1 both;
  @keyframes showFromTop {
    0% {
      opacity: 0;
      transform: translate(0, -32px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
.show-from-bottom {
  animation: showFromBottom 0.7s ease 1 both;
  @keyframes showFromBottom {
    0% {
      opacity: 0;
      transform: translate(0, 32px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
.scale-from-left {
  animation: scaleFromLeft 0.7s ease 1 both;
  @keyframes scaleFromLeft {
    0% {
      transform-origin: 0 50%;
      transform: scale(0, 1);
    }
    100% {
      transform-origin: 0 50%;
      transform: scale(1, 1);
    }
  }
}
.scale-size {
  animation: scaleSize 0.7s ease 1 both;
  @keyframes scaleSize {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
.animation-delay-300 {
  animation-delay: 0.3s;
}
.animation-delay-600 {
  animation-delay: 0.6s;
}
.animation-delay-900 {
  animation-delay: 0.9s;
}
.animation-delay-1200 {
  animation-delay: 1.2s;
}
.animation-delay-1500 {
  animation-delay: 1.5s;
}

.bg-primary {
  background-color: $primary;
}
.bg-indigo-600 {
  background: $primary;
}
.border-indigo-400,
.border-indigo-600 {
  border-color: $primary;
}
.ring-indigo-600 {
  --tw-ring-color: rgba(0, 0, 255, var(--tw-ring-opacity));
}
.text-indigo-600 {
  color: $primary;
}

.border-loading {
  border-top-color: transparent;
}
.ratio-16x9 {
  padding-bottom: 56.25%;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.notie-textbox {
  font-size: 16px;
}

#firebaseui_container {
  .firebaseui-idp-button {
    border-radius: 9999px;
    &[data-provider-id="apple.com"] .firebaseui-idp-text {
      visibility: hidden;
      font-size: 0;
      &:before {
        font-size: 14px;
        content: "Войти через Apple ID";
        visibility: visible;
      }
    }
    &[data-provider-id="google.com"] .firebaseui-idp-text {
      visibility: hidden;
      font-size: 0;
      &:before {
        font-size: 14px;
        content: "Войти через Google";
        visibility: visible;
      }
    }
    &[data-provider-id="phone"] .firebaseui-idp-text {
      visibility: hidden;
      font-size: 0;
      &:before {
        font-size: 14px;
        content: "Войти через СМС";
        visibility: visible;
      }
    }
    &[data-provider-id="password"] .firebaseui-idp-text {
      visibility: hidden;
      font-size: 0;
      &:before {
        font-size: 14px;
        content: "Войти через e-mail";
        visibility: visible;
      }
    }
    &[data-provider-id="anonymous"] {
      background-color: $primary !important;
    }
    &[data-provider-id="anonymous"] .firebaseui-idp-text {
      visibility: hidden;
      font-size: 0;
      &:before {
        font-size: 14px;
        content: "Подключиться";
        visibility: visible;
      }
    }
  }
}

// timetable
.hide-timetable {
  animation: showFromBottom 0.5s ease 1 both;
  animation-direction: reverse;
}
.show-timetable {
  .logo {
    animation: showFromTop 0.5s ease 1 both;
  }
  .logo-title {
    animation: showFromTop 0.5s 0.3s ease 1 both;
  }

  .photo,
  .soon,
  .name,
  .title {
    animation: showFromTop 0.5s ease 1 both;
  }
  .soon {
    animation-delay: 0.3s;
  }
  .name {
    animation-delay: 0.6s;
  }
  .title {
    animation-delay: 0.9s;
  }
  .speakers {
    animation: scaleFromLeft 0.5s 0.5s ease 1 both;
    .speaker-item {
      animation: scaleSize 0.25s ease 1 both;
    }
    @for $speaker from 1 to 20 {
      .speaker-item:nth-child(#{$speaker}) {
        animation-delay: 1s + (0.15s) * $speaker;
      }
    }
  }
}

.squid-run {
  &.start {
    .logo {
      display: block;
      left: 50%;
      animation: showLogoSquidRope 6s ease 1 both;
      transform: translate(-50%, -50%);
      @keyframes showLogoSquidRope {
        0% {
          top: 100%;
        }
        20% {
          top: 50%;
        }
        60% {
          top: 50%;
        }
        100% {
          top: -300px;
        }
      }
    }
    .field {
      animation: showFieldSquidRope 6s ease 1 both;
      @keyframes showFieldSquidRope {
        0% {
          top: 100%;
        }
        20% {
          top: 100%;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }
    .actions,
    .rules {
      animation: showActionsSquidRope 6s ease 1 both;
      @keyframes showActionsSquidRope {
        0% {
          transform: translate(0, 100%);
          opacity: 0;
        }
        70% {
          transform: translate(0, 100%);
          opacity: 0;
        }
        100% {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
  &.eyes-close,
  &.eyes-soon-open {
    .eyes {
      display: block;
      animation: showFromTop 0.6s ease 1 both;
      background-image: url("../../../assets/squid-run/eye-close.svg");
    }
    .stats {
      display: flex;
      animation: showFromTop 0.6s ease 1 both;
    }
    .rules {
      animation: showFromTop 0.6s ease 1 both;
      animation-direction: reverse;
    }
    .pulse-btn {
      animation-play-state: running;
    }
  }
  &.eyes-soon-open {
    .stats {
      display: flex;
      animation: showFromTop 0.6s ease 1 both;
    }
    .eyes {
      display: block;
      animation: showFromTop 0.6s ease 1 both;
      background-image: url("../../../assets/squid-run/eye-sub-on.svg");
    }
  }
  &.eyes-open {
    .stats {
      display: flex;
      animation: showFromTop 0.6s ease 1 both;
    }
    .eyes {
      display: block;
      animation: showFromTop 0.6s ease 1 both;
      background-image: url("../../../assets/squid-run/eye-open.svg");
    }
    .rules {
      animation: showFromTop 0.6s ease 1 both;
      animation-direction: reverse;
    }
    .pulse-btn {
      animation-play-state: paused;
    }
  }
  &.win {
    .eyes {
      display: block;
      animation: growUpDown 2s ease infinite both;
      background-position: center center;
      background-image: url("../../../assets/squid-run/win.svg");
    }
  }
  .logo,
  .stats {
    display: none;
  }
  .pulse-btn {
    animation: pulse 3s ease-in-out infinite both;
  }
  .eyes {
    display: none;
    background: no-repeat top center;
    background-size: contain;
  }
}
.squid-run:after {
  content: "";
  display: block;
  padding-top: 100%;
}

.squid-run .person {
  animation: personRun 0.6s linear infinite both;
  background: center center no-repeat;
  animation-play-state: paused;
  background-size: contain;
  padding-top: 100%;
  bottom: 0;
  .photo {
    background: center center no-repeat white;
    background-size: cover;
    width: 32%;
    height: 32%;
    left: 41%;
    top: 6%;
    position: absolute;
    border-radius: 100%;
    animation: personRunPhoto 0.6s linear infinite both;
    animation-play-state: paused;
  }
  &.running,
  &.running .photo {
    animation-play-state: running;
  }
  &.dead {
    animation: personDie 0.8s linear 1 both;
    animation-play-state: running;
    .photo {
      animation: personPhotoDie 0.8s linear 1 both;
      animation-play-state: running;
    }
    @keyframes personPhotoDie {
      0% {
        left: 42%;
        top: 6%;
        transform: rotate(15deg);
      }
      10% {
        left: 42%;
        top: 6%;
        transform: rotate(15deg);
      }
      30% {
        left: 55%;
        top: 27%;
        transform: rotate(40deg);
      }
      60% {
        animation: none;
        left: 65%;
        top: 60%;
        transform: rotate(65deg);
      }
      90% {
        left: 70%;
        top: 70%;
        transform: rotate(75deg);
      }
      100% {
        left: 70%;
        top: 70%;
        transform: rotate(75deg);
      }
    }
    @keyframes personDie {
      0% {
        background-image: url("../../../assets/squid-run/run/dead/9.svg");
      }
      10% {
        background-image: url("../../../assets/squid-run/run/dead/9.svg");
      }
      30% {
        background-image: url("../../../assets/squid-run/run/dead/10.svg");
      }
      60% {
        background-image: url("../../../assets/squid-run/run/dead/11.svg");
      }
      90% {
        background-image: url("../../../assets/squid-run/run/dead/12.svg");
      }
      100% {
        background-image: url("../../../assets/squid-run/run/dead/12.svg");
      }
    }
  }
  @keyframes personRunPhoto {
    0% {
      left: 41%;
      top: 5%;
    }
    15% {
      left: 41%;
      top: 7%;
    }
    30% {
      left: 40%;
      top: 7%;
    }
    45% {
      left: 42%;
      top: 4%;
    }
    60% {
      left: 44%;
      top: 4%;
    }
    75% {
      left: 42%;
      top: 6%;
    }
    90% {
      left: 41%;
      top: 6%;
    }
    100% {
      left: 41%;
      top: 5%;
    }
  }
  @keyframes personRun {
    0% {
      background-image: url("../../../assets/squid-run/run/1.svg");
    }
    15% {
      background-image: url("../../../assets/squid-run/run/2.svg");
    }
    30% {
      background-image: url("../../../assets/squid-run/run/3.svg");
    }
    45% {
      background-image: url("../../../assets/squid-run/run/4.svg");
    }
    60% {
      background-image: url("../../../assets/squid-run/run/5.svg");
    }
    75% {
      background-image: url("../../../assets/squid-run/run/6.svg");
    }
    90% {
      background-image: url("../../../assets/squid-run/run/7.svg");
    }
    100% {
      background-image: url("../../../assets/squid-run/run/1.svg");
    }
  }
}

.btn-squid {
  background: #db2377;
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.squid-rope {
  &.start {
    .logo {
      display: block;
      left: 50%;
      animation: showLogoSquidRope 6s ease 1 both;
      transform: translate(-50%, -50%);
      @keyframes showLogoSquidRope {
        0% {
          top: 100%;
        }
        20% {
          top: 50%;
        }
        60% {
          top: 50%;
        }
        100% {
          top: -300px;
        }
      }
    }
    .field {
      animation: showFieldSquidRope 6s ease 1 both;
      @keyframes showFieldSquidRope {
        0% {
          top: 100%;
        }
        20% {
          top: 100%;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }
    .actions,
    .rules {
      animation: showActionsSquidRope 6s ease 1 both;
      @keyframes showActionsSquidRope {
        0% {
          transform: translate(0, 100%);
          opacity: 0;
        }
        70% {
          transform: translate(0, 100%);
          opacity: 0;
        }
        100% {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
  .results {
    display: none;
    .percent {
      transition: 0.3s ease;
    }
    .bigger {
      transform: scale(1.3) translate(0, -3px);
    }
  }
  .logo {
    display: none;
  }
  .track {
    animation: track 3s ease-in-out infinite both;
  }
  .pulse-btn {
    animation: pulse 3s ease-in-out infinite both;
  }
  .btn-green {
    animation: btnGreen 3s ease-in-out infinite both;
  }
  .btn-yellow {
    animation: btnYellow 3s ease-in-out infinite both;
  }
  .person,
  .person .photo {
    animation-play-state: paused;
  }
  .result {
    animation: showOpacity 1.2s ease 1 both;
    @keyframes showOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.speed-1 {
    .results {
      display: block;
    }
    .rules {
      opacity: 0;
    }
    .person,
    .person .photo {
      animation-play-state: running;
    }
    .players {
      animation: movePlayers 3s ease infinite both;
    }
  }
  &.speed-2 {
    .results {
      display: block;
    }
    .rules {
      opacity: 0;
    }
    .person,
    .person .photo {
      animation-play-state: running;
    }
    .track {
      animation: track 2.2s ease-in-out infinite both;
    }
    .pulse-btn {
      animation: pulse 2.2s ease-in-out infinite both;
    }
    .btn-green {
      animation: btnGreen 2.2s ease-in-out infinite both;
    }
    .btn-yellow {
      animation: btnYellow 2.2s ease-in-out infinite both;
    }
    .players {
      animation: movePlayers1 2.2s ease infinite both;
    }
  }
  &.speed-3,
  &.squid-results {
    .rules {
      opacity: 0;
    }
    .results {
      display: block;
    }
    .person,
    .person .photo {
      animation-play-state: running;
    }
    .track {
      animation: track 1.5s ease-in-out infinite both;
    }
    .pulse-btn {
      animation: pulse 1.5s ease-in-out infinite both;
    }
    .btn-green {
      animation: btnGreen 1.5s ease-in-out infinite both;
    }
    .btn-yellow {
      animation: btnYellow 1.5s ease-in-out infinite both;
    }
    .players {
      animation: movePlayers2 1.5s ease infinite both;
    }
  }
  &.squid-results.blue-win .players {
    animation: movePlayersLeft 1.3s ease 1 both;
  }
  &.squid-results.red-win .players {
    animation: movePlayersRight 1.3s ease 1 both;
  }
  .text-hint {
    animation: showHint 0.4s ease 1 both;
    @keyframes showHint {
      0% {
        transform: translate(0, 0);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translate(0, -40px);
      }
    }
  }
}

.squid-rope:after {
  content: "";
  display: block;
  padding-top: 80%;
}

.squid-rope .person {
  animation: person 0.6s linear infinite both;
  background: center center no-repeat;
  background-size: contain;
  padding-top: 50%;
  bottom: 0;
  &:nth-child(1),
  &:nth-child(1) .photo {
    animation-delay: 0.15s;
  }
  &:nth-child(2),
  &:nth-child(2) .photo {
    animation-delay: 0.22s;
  }
  &:nth-child(3),
  &:nth-child(3) .photo {
    animation-delay: 0.35s;
  }
  &-reverse {
    transform: scale(-1, 1);
    .photo {
      transform: scale(-1, 1);
    }
  }
  .photo {
    background: center center no-repeat white;
    background-size: cover;
    width: 30%;
    height: 30%;
    left: 22%;
    top: 11%;
    position: absolute;
    border-radius: 100%;
    animation: personPhoto 0.6s linear infinite both;
  }
  @keyframes personPhoto {
    0% {
      left: 20%;
      top: 11%;
    }
    15% {
      left: 33%;
      top: 11%;
    }
    30% {
      left: 27%;
      top: 10%;
    }
    45% {
      left: 14%;
      top: 10%;
    }
    60% {
      left: 9%;
      top: 12%;
    }
    75% {
      left: 14%;
      top: 13%;
    }
    90% {
      left: 18%;
      top: 12%;
    }
    100% {
      left: 20%;
      top: 11%;
    }
  }
  @keyframes person {
    0% {
      background-image: url("../../../assets/squid/rope/1.svg");
    }
    15% {
      background-image: url("../../../assets/squid/rope/2.svg");
    }
    30% {
      background-image: url("../../../assets/squid/rope/3.svg");
    }
    45% {
      background-image: url("../../../assets/squid/rope/4.svg");
    }
    60% {
      background-image: url("../../../assets/squid/rope/5.svg");
    }
    75% {
      background-image: url("../../../assets/squid/rope/6.svg");
    }
    90% {
      background-image: url("../../../assets/squid/rope/7.svg");
    }
    100% {
      background-image: url("../../../assets/squid/rope/1.svg");
    }
  }
}

.btn-squid {
  background: #db2377;
}

@keyframes movePlayersLeft {
  0% {
    transform: translate(1%);
  }
  100% {
    transform: translate(-20%);
  }
}
@keyframes movePlayersRight {
  0% {
    transform: translate(-1%);
  }
  100% {
    transform: translate(20%);
  }
}

@keyframes movePlayers {
  0% {
    transform: translate(-1%);
  }
  50% {
    transform: translate(1%);
  }
  100% {
    transform: translate(-1%);
  }
}
@keyframes movePlayers1 {
  0% {
    transform: translate(-1.5%);
  }
  50% {
    transform: translate(1.5%);
  }
  100% {
    transform: translate(-1.5%);
  }
}
@keyframes movePlayers2 {
  0% {
    transform: translate(-2.2%);
  }
  50% {
    transform: translate(2.2%);
  }
  100% {
    transform: translate(-2.2%);
  }
}

@keyframes btnGreen {
  0% {
    z-index: 0;
  }
  23.5% {
    z-index: 0;
  }
  24% {
    z-index: 10;
  }
  26% {
    z-index: 10;
  }
  26.5% {
    z-index: 0;
  }
  50% {
    z-index: 0;
  }
  73.5% {
    z-index: 0;
  }
  74% {
    z-index: 10;
  }
  76% {
    z-index: 10;
  }
  76.5% {
    z-index: 0;
  }
  100% {
    z-index: 0;
  }
}

@keyframes btnYellow {
  0% {
    z-index: 0;
  }
  19.5% {
    z-index: 0;
  }
  20% {
    z-index: 9;
  }
  30% {
    z-index: 9;
  }
  30.5% {
    z-index: 0;
  }
  50% {
    z-index: 0;
  }
  69.5% {
    z-index: 0;
  }
  70% {
    z-index: 9;
  }
  80% {
    z-index: 9;
  }
  80.5% {
    z-index: 0;
  }
  100% {
    z-index: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes track {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

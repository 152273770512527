.chat-message {
  max-width: calc(100% - 100px);
  &.hidden {
    display: none;
  }
  &.mine {
    display: block;
    .photo {
      right: -40px;
      left: auto;
    }
    .btn-hide {
      right: auto;
      left: -32px;
    }
  }
  &.moderator {
    display: block;
    &.hidden {
      background: #f9f9f9;
      color: #d0d0d0;
      .text {
        max-height: 20px;
        overflow: hidden;
      }
      .author .text-primary {
        color: #d0d0d0 !important;
      }
    }
  }
  &.admin {
    display: block;
    .like-btn {
      display: none !important;
    }
    &.hidden {
      background: #f9f9f9;
      color: #d0d0d0;
      .text {
        max-height: 20px;
        overflow: hidden;
      }
      .author .text-primary {
        color: #d0d0d0 !important;
      }
    }
  }
  .photo {
    bottom: 5px;
    left: -40px;
  }
}

.chat::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
.chat::-webkit-scrollbar-track {
  background-color: rgba(79, 70, 229, 0.1);
  cursor: pointer;
}
.chat::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: rgb(0, 3, 10);
}
